import { ComponentProps } from 'preact';
import { forwardRef } from 'preact/compat';

type ProtectedImgProps = ComponentProps<'img'>;

const preventDefault = (e: Event): void => e.preventDefault();

const ProtectedImg = forwardRef<HTMLImageElement, ProtectedImgProps>(({ alt, ...props }, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <img draggable={false} onContextMenu={preventDefault} alt={alt} ref={ref} {...props} />
));

export default ProtectedImg;
